<template>
  <div class="VirHomePart3">
    <!-- <img class="rightImg" style="left: 0;top: 38%;" v-lazy="$StaticFileUrl + '/mp/img/brief_bag.png'" alt=""/>
    <img class="rightImg" style="right: 0;top: 38%;" v-lazy="$StaticFileUrl + '/mp/img/blief_bag.png'" alt=""/> -->

    <div class="reply_title">多生态商城构建</div>
    <p class="texts" style="margin-bottom: 80px">
      适用于微信公众号/小程序、H5页面、PC端、APP等平台
    </p>

    <div class="reply_content align-items">
      <div class="reply_buttom">
        <div
          class="align-items"
          v-for="(item, index) in VirHomePart3_Data"
          :key="index"
          :class="{ activebox: activeKey === index }"
          @mouseenter="activeKey = index"
        >
          <img
            style="width: 40px; height: 40px"
            :src="activeKey === index ? item.selectIcon : item.icon"
            :alt="item.title"
          />
          <div class="headline">
            <div class="headline_title">
              {{ item.title }}
            </div>
            <div class="headline_p">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>

      <div class="reply_right">
        <div class="reply_img">
          <div class="bg"></div>
          <div class="reply_neibui">
            <div class="choice_wrapper">
              <img
                style="height: 510px"
                v-for="(item, index) in VirHomePart3_Data"
                :key="index"
                :src="item.imgUrl"
                :class="{ img_active: activeKey === index, img_box: true }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/26 13:47
 * @version 1.0
 * @description
 */
import WOW from "wowjs";
import VirHomePart3_Data from "@/views/ProductServer/VirHome/Data/VirHomePart3_Data";

export default {
  name: "VirHomePart3",
  data() {
    return {
      activeKey: 0,
      VirHomePart3_Data,
    };
  },
  mounted() {
    new WOW.WOW().init();
  },
};
</script>

<style scoped lang="scss">
.VirHomePart3 {
  position: relative;

  background: #f6f8fa;
  padding: 120px 0;
  .reply_title {
    font-size: 40px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei, serif;
    font-weight: 400;
    color: #222;
    text-align: center;
    margin-bottom: 28px;
  }
  .reply_p {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #707070;
    text-align: center;
    line-height: 32px;
    margin-bottom: 108px;
  }
}
.reply_content {
  width: 1280px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .reply_buttom {
    flex: 1;
    padding-left: 56px;
    box-sizing: border-box;
    > div {
      padding: 10px 19px 8.5px 34px;
      cursor: pointer;
      height: 75px;
      background: #fff;
      display: flex;
      flex-direction: row;
      justify-content: left;
      margin-bottom: 15px;
      img {
        width: 35px;
        height: 33px;
        margin-right: 25px;
      }
      .headline {
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
        font-weight: 400;
        color: #222;
        .headline_title {
          font-size: 22px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
          font-weight: 400;
          color: #222;
        }
        .headline_p {
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #707070;
        }
      }
    }
    .activebox {
      background: #f5f9fc;
      .headline {
        color: #ff6a00;
        .headline_title {
          color: #ff6a00;
        }
      }
    }
  }
  .reply_right {
    height: 500px;
    margin-left: 30px;
    display: flex;
    justify-content: left;
    align-items: center;
    flex: 1;
    position: relative;
    > div {
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }
    // TODOS  替换图片
    .reply_img {
      // background-image: url("https://resource.duobaoyu.com.cn/website/bj_7915.png");
      position: relative;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 70% 80%;
      transition: all 2s;
      .bg {
        width: 380px;
        height: 200px;
        background-color: #ff6a00;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) skewY(15deg);
        z-index: 0;
      }
      .reply_neibui {
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translateX(-50%);
        width: 256px;
        height: 538px;
        .img_box {
          width: 100%;
          height: 100%;
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          &.img_active {
            opacity: 1;
            animation: fadeIn 0.3s ease-in-out;
          }
        }
      }
      .choice_wrapper {
        position: relative;
        z-index: 99;
        overflow: hidden;
        width: 256px;
        height: 538px;
        border: 6px solid #222;
        border-radius: 40px;
        background: #fff;
      }
    }
  }
}
</style>
