<template>
  <div class="VirHomePart4">
    <div class="wow animate__zoomIn" data-wow-duration="1s">
      <div class="block_maintitle" style="text-align: center">产品服务</div>
      <div class="block_subtitle" style="text-align: center">
        轻松实现用户拉新、促活、转化的目的
      </div>
    </div>

    <div class="product_content">
      <div
        class="product_li"
        v-for="(item, index) in VirHomePart4_Data"
        :key="index"
      >
        <div class="product_lis">
          <div class="product_headline">
            <img :src="item.imgUrl" :alt="item.title" />
          </div>
          <div class="product_right">
            <div class="product_effect">{{ item.title }}</div>
            <div class="product_subtitle">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/26 14:24
 * @version 1.0
 * @description
 */
import WOW from "wowjs";
import VirHomePart4_Data from "@/views/ProductServer/VirHome/Data/VirHomePart4_Data";
export default {
  name: "VirHomePart4",
  data() {
    return {
      VirHomePart4_Data,
    };
  },
  mounted() {
    new WOW.WOW().init();
  },
};
</script>

<style scoped lang="scss">
.VirHomePart4 {
  width: 100%;
  position: relative;
  padding: 120px 0;
}

.product_content {
  width: 1280px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 60px auto auto;
  .product_li {
    border-radius: 10px;
    width: 300px;
    height: 428px;
    transition: all 0.3s;
    &:hover {
      transform: translateY(-15px);
    }
    .product_lis {
      width: 100%;
      height: 100%;
      overflow: hidden;
      background: #fff;
      box-shadow: 0 3px 29px 1px rgba(73, 75, 78, 0.16);
      border-radius: 10px;
      transition: all 0.6s;
      .product_headline {
        width: 300px;
        height: 210px;
        img {
          width: 300px;
          height: 200px;
        }
      }
      .product_right {
        padding: 0 20px;
        .product_effect {
          background: #fff;
          font-size: 24px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
          font-weight: 400;
          color: #333;
          line-height: 26px;
          margin: 18px 0 28px;
        }
        .product_subtitle {
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
          font-weight: 400;
          color: #999;
          line-height: 23px;
        }
      }
    }
  }
}
</style>
