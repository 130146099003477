/**
 * @author  XuHongli
 * @date  2022/12/26 13:40
 * @version 1.0
 * @description
 */
const VirHomePart3_Data = [
  {
    title: 'APP',
    text: 'APP嵌入式商域，实现个性化配置丰富APP用户场景',
    icon: require('@/assets/ji/APP1.png'),
    selectIcon: require('@/assets/ji/APP.png'),
    imgUrl:  '/mp/sc_imgs_007.png'
  },
  {
    title: '公众号',
    text: '快速设置菜单，3分钟高效接入聚合商城',
    icon: require('@/assets/ji/wx1.png'),
    selectIcon: require('@/assets/ji/wx.png'),
    imgUrl: '/mp/gzh_002.png'
  },
  {
    title: 'H5',
    text: '快速入口，轻松获客、裂变、复购',
    icon: require('@/assets/ji/phone1.png'),
    selectIcon: require('@/assets/ji/phone.png'),
    imgUrl: '/mp/kj_003_2.png'
  },
  {
    title: '小程序',
    text: '打造专属私域流量池，粉丝深度营销',
    icon: require('@/assets/ji/xcx1.png'),
    selectIcon: require('@/assets/ji/xcx.png'),
    imgUrl: '/mp/xcx_004_2.png'
  },
  {
    title: 'API',
    text: '提供API接口对接方式',
    icon: require('@/assets/ji/API1.png'),
    selectIcon: require('@/assets/ji/API.png'),
    imgUrl: '/mp/sc_img_005.png'
  }
]

export default VirHomePart3_Data
