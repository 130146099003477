<template>
  <div class="virHome">
    <!-- <div class="bgone">
      <div class="bgone_tit">
        <div>
          <div><h1>为企业提供丰富的数字权益服务</h1></div>
          <div style="width: 390px; font-size: 18px; color: #666">
            <p>
              为企业提供丰富的数字化权益、产品及服务，帮助所有客户实现可持续的商业增长
            </p>
          </div>
        </div>
      </div>
    </div> -->
    <VirHomePart1></VirHomePart1>
    <VirHomePart2></VirHomePart2>
    <VirHomePart3></VirHomePart3>
    <VirHomePart4></VirHomePart4>
    <VirHomePart5></VirHomePart5>
    <VirHomePart6></VirHomePart6>
    <VirHomePart7></VirHomePart7>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/22 17:43
 * @version 1.0
 * @description 数字权益页面
 */
import WOW from "wowjs";
import BannerVideo from "@/components/BannerVideo";
import VirHomePart1 from "@/views/ProductServer/VirHome/VirHomePart1";
import VirHomePart2 from "@/views/ProductServer/VirHome/VirHomePart2";
import VirHomePart7 from "@/views/ProductServer/VirHome/VirHomePart7";
import VirHomePart6 from "@/views/ProductServer/VirHome/VirHomePart6";
import VirHomePart3 from "@/views/ProductServer/VirHome/VirHomePart3";
import VirHomePart4 from "@/views/ProductServer/VirHome/VirHomePart4";
import VirHomePart5 from "@/views/ProductServer/VirHome/VirHomePart5";
export default {
  name: "VirHome",
  components: {
    VirHomePart5,
    VirHomePart4,
    VirHomePart3,
    VirHomePart6,
    VirHomePart7,
    VirHomePart2,
    VirHomePart1,
    BannerVideo,
  },
  mounted() {
    new WOW.WOW().init();
  },
};
</script>

<style scoped lang="scss">
.bgone {
  background: url(../../../assets/home/bg1.png) no-repeat;
  height: 450px;
  width: 100%;
  background-size: 100% 100%;
  &_tit {
    width: 1440px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
