<template>
  <div class="VirHomePart2">
    <div class="brand_block wow animate__zoomIn" data-wow-duration="1s">
      <div class="block_maintitle">产品优势</div>
    </div>
    <div
      class="tabs-has-line wow animate__fadeInUpBig"
      data-wow-duration="0.5s"
    >
      <a-tabs v-model="activeKey">
        <a-tab-pane key="品类齐全" tab="品类齐全" style="height: 500px">
          <div class="advantage_content" v-if="activeKey === '品类齐全'">
            <div class="advantage_img">
              <img
                class="img-object last animation-duration-2 animate__fadeInLeft wow"
                data-wow-duration="2s"
                style="margin-right: 150px"
                src="/mp/VirHome/nengsss.png"
                v-lazy="'/mp/VirHome/nengsss.png'"
              />
            </div>
            <div
              class="advantage_title wow animate__fadeInRight animation-duration-1"
              data-wow-duration="2s"
            >
              <div class="advantage_headline">品类齐全</div>
              <div class="advantage_subtitle">
                整合C端用户高频使用的虚拟数字产品，集成了包含话费、流量、加油卡、影视会员等销量领先的类目
              </div>
              <div class="bottom_push1">
                <a href="javascript:void(0)">立即体验 ></a>
              </div>
              <div class="bottom_push2">
                <div>
                  <div>
                    <div><span>100</span> <i>+ </i></div>
                  </div>
                  <div>品类数</div>
                </div>
                <div>
                  <div><span>2000</span> <i>万+</i></div>
                  <div>销售额</div>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>

        <a-tab-pane key="强势货源" tab="强势货源">
          <div class="advantage_content" v-if="activeKey === '强势货源'">
            <div class="advantage_img advantage_box">
              <!-- <img
                class="img_box_center"
                :src="'/mp/qs_img_005.png'" > -->
              <img
                style="width: 300px; height: 240px; margin-top: 60px"
                class="img_box_center"
                :src="'/mp/7.png'"
              />
              <img
                class="img_first animation-duration-1 animate__fadeInRight wow"
                data-wow-duration="1s"
                style="width: 140px; height: 158px"
                src="/mp/qs_img_005.png"
              />
              <img
                class="img_third animation-duration-1 animate__fadeInLeft wow"
                data-wow-duration="1s"
                style="width: 140px; height: 120px"
                src="/mp/2(1).png"
              />
              <img
                class="img_three animation-duration-1 animate__fadeInRight wow"
                data-wow-duration="1s"
                style="width: 120px; height: 130px"
                src="/mp/1.png"
              />
              <img
                class="img_four animation-duration-1 animate__fadeInLeft wow"
                data-wow-duration="1s"
                style="width: 130px; height: 140px"
                src="/mp/3.png"
              />
            </div>
            <div
              class="advantage_title wow animate__fadeInRight animation-duration-1"
              data-wow-duration="2s"
            >
              <div class="advantage_headline">强势货源</div>
              <div class="advantage_subtitle">
                凭借雄厚资金实力，整合行业优势资源，与超过20个总代品牌合作，保障产品优质低价
              </div>
              <div class="bottom_push1">
                <a href="javascript:void(0)">立即体验 ></a>
              </div>
              <div class="bottom_push2">
                <div>
                  <div>
                    <div><span>500</span> <i>+ </i></div>
                  </div>
                  <div>品牌合作</div>
                </div>
                <div>
                  <div><span>5000</span> <i>万+</i></div>
                  <div>年投入</div>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>

        <a-tab-pane key="定制服务" tab="定制服务">
          <div class="advantage_content" v-if="activeKey === '定制服务'">
            <div class="advantage_img advantage_dz">
              <img
                class="advantage_dz_img animation-duration-2 animate__zoomInReverse wow"
                data-wow-duration="2s"
                width="600px"
                :src="'/mp/xlsc_img_0003.png'"
              />
            </div>
            <div
              class="advantage_title wow animate__fadeInRight animation-duration-1"
              data-wow-duration="2s"
            >
              <div class="advantage_headline">定制服务</div>
              <div class="advantage_subtitle">
                提供定制化服务，包括但不限于面额定制等服务
              </div>
              <div class="bottom_push1">
                <a href="javascript:void(0)">立即体验 ></a>
              </div>
              <div class="bottom_push2">
                <div>
                  <div><span>200</span> <i>万+</i></div>
                  <div>年定制量</div>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>

        <a-tab-pane key="接口稳定" tab="接口稳定">
          <div class="advantage_content" v-if="activeKey === '接口稳定'">
            <div class="advantage_img">
              <img
                class="animation-duration-1 animate__zoomIn wow"
                data-wow-duration="1s"
                style="width: 600px"
                :src="'/mp/xlsc_img_0004.png'"
              />
            </div>
            <div
              class="advantage_title wow animate__fadeInRight animation-duration-1"
              data-wow-duration="2s"
            >
              <div class="advantage_headline">接口稳定</div>
              <div class="advantage_subtitle">
                专业技术团队保证API接口安全、稳定，用户从下单到充值成功仅需3秒
              </div>
              <div class="bottom_push1">
                <a href="javascript:void(0)">立即体验 ></a>
              </div>
              <div class="bottom_push2">
                <div>
                  <div>
                    <div><span>99.9</span> <i>% </i></div>
                  </div>
                  <div>连通率</div>
                </div>
                <div>
                  <div><span>1</span> <i>万+</i></div>
                  <div>对接企业</div>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/23 18:57
 * @version 1.0
 * @description
 */
import WOW from "wowjs";
export default {
  name: "VirHomePart2",
  data() {
    return {
      activeKey: "品类齐全",
    };
  },
  mounted() {
    new WOW.WOW().init();
    document
      .querySelectorAll(".VirHomePart2 .tabs-has-line .ant-tabs-nav>div div")
      .forEach((ele) => {
        ele.addEventListener("mouseenter", this.mouseEnterTabsEvent);
      });
  },
  beforeDestroy() {
    document
      .querySelectorAll(".VirHomePart2 .tabs-has-line .ant-tabs-nav>div div")
      .forEach((ele) => {
        ele.removeEventListener("mouseenter", this.mouseEnterTabsEvent);
      });
  },
  methods: {
    mouseEnterTabsEvent(e) {
      this.activeKey = e.target.innerText;
    },
  },
};
</script>

<style scoped lang="scss">
.VirHomePart2 {
  width: 100%;
  background: #fff;
  padding: 120px 0;
  text-align: center;
  position: relative;
}
.advantage_img {
  .first {
    position: absolute;
    left: 0;
    top: 0;
    width: 230px;
    height: 247px;
  }
  .third {
    position: absolute;
    left: 20%;
    top: 5%;
    width: 288px;
    height: 343px;
  }
  .last {
    position: absolute;
    left: 12%;
    top: 5%;
    width: 470px;
    height: 400px;
  }
}
</style>
<style scoped lang="scss">
.advantage_img {
  &.advantage_box {
    position: relative;
    background-size: 70% 100%;
    padding-top: 100px;
    box-sizing: border-box;
    img {
      width: 550px;
      height: 750px;
    }
    .img_box_center {
      position: absolute;
      top: 0;
      left: 28%;
      width: 550px;
      height: 800px;
    }
    .img_first {
      position: absolute;
      top: -10px;
      left: 60px;
    }
    .img_third {
      position: absolute;
      top: -10px;
      right: 30px;
    }
    .img_three {
      position: absolute;
      bottom: -30px;
      left: 70px;
    }
    .img_four {
      position: absolute;
      bottom: -30px;
      right: 40px;
    }
  }
}
</style>
<style lang="scss" scoped>
// TODOS 背景替换
.advantage_img {
  &.advantage_dz {
    background-position: top;
    background-size: 82% 100%;
    transition: all 2s;
    // background-image: url("https://resource.duobaoyu.com.cn/website/bj_img_010.png");
    .advantage_dz_img {
      position: absolute;
      top: -30px;
      transition: all 1s;
      opacity: 1;
      animation: 1s zoomInReverse;
    }
  }
}
</style>
