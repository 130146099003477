/**
 * @author  XuHongli
 * @date  2022/12/26 14:30
 * @version 1.0
 * @description
 */

const VirHomePart4_Data = [
  {
    title: '充值API',
    text: '提供专业的数字权益商品标准化接口和免费接入服务，丰富企业的产品内容、提升竞争优势',
    imgUrl: require('@/assets/ji/11.png')
  },
  {
    title: '权益商城',
    text: '数字权益商品提供模块化权益商城搭建服务，快速创建属于自己的权益商城',
    imgUrl: require('@/assets/ji/12.png')
  },
  {
    title: '批充批采',
    text: '提供数字权益商品的大批量充值与采购服务。有助于企业开展用户运营、渠道分销、福利发放等活动',
    imgUrl: require('@/assets/ji/13.png')
  },
  {
    title: '企业福利',
    text: '提供全场景员工福利解决方案，企业省心、员工满意',
    imgUrl: require('@/assets/ji/14.png')
  }
]

export default VirHomePart4_Data
