<template>
  <div class="VirHomePart7">
    <div class="bgss">
      <div class="brand_block wow animate__zoomIn" data-wow-duration="1s">
        <div class="block_maintitle">合作伙伴</div>
      </div>
      <div
        class="contWidth1440 marginAuto animate__fadeInUp join_content"
        style="margin-bottom: 120px"
        data-wow-duration="1s"
      >
        <img style="width: 100%; height: 554px" src="/mp/bjs.png" />
      </div>
    </div>
    <!-- <img class="rightImg" style="left: 0;top: 56%;" v-lazy="$StaticFileUrl + '/mp/img/brief_bag.png'" alt=""/>
    <img class="rightImg" style="right: 0;top: 56%;" v-lazy="$StaticFileUrl + '/mp/img/blief_bag.png'" alt=""/> -->
    <HomeFooterBanner></HomeFooterBanner>
  </div>
</template>

<script>
import HomeFooterBanner from "@/Layouts/components/FooterBanner/footerBanner/index.vue";

export default {
  name: "VirHomePart7",
  components: {
    HomeFooterBanner,
  },
};
</script>

<style scoped lang="scss">
.VirHomePart7 {
  position: relative;
}
.join_content {
  width: 1440px;
  margin: 0 auto;
  img {
    width: 1440px;
    height: 746px;
  }
}
.bgss {
  width: 100%;
  background: #f6f8fa;
  padding: 80px 0;
}
</style>
