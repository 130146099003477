<template>
  <div class="VirHomePart1">
    <div class="brand_block wow animate__zoomIn" data-wow-duration="1s">
      <div class="block_maintitle">海量商品全覆盖</div>
      <div class="block_subtitle">
        多元化消费商品全覆盖，官方品质保证，售后无忧
      </div>
    </div>

    <!-- <img class="rightImg" style="left: 0;top: 33%;" v-lazy="$StaticFileUrl + '/mp/img/brief_bag.png'" alt=""/>

    <img class="rightImg" style="right: 0;top: 33%;" v-lazy="$StaticFileUrl + '/mp/img/blief_bag.png'" alt=""/> -->

    <div class="tabs wow animate__fadeInUpBig" data-wow-duration="1s">
      <a-tabs v-model="activeKey">
        <a-tab-pane
          class="tab-pane"
          :key="item.name"
          :tab="item.name"
          v-for="item in VirHomePart1_Data"
        >
          <a-row :gutter="[40, 30]" style="padding: 0 100px; height: 380px">
            <a-col :span="3" v-for="(el, index) in item.children" :key="index">
              <div class="brand_box">
                <img v-lazy="el.imgUrl" class="brand_img" />
                <div class="brand_title">{{ el.name }}</div>
              </div>
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/23 15:20
 * @version 1.0
 * @description
 */
import VirHomePart1_Data from "./Data/VirHomePart1_Data";
import WOW from "wowjs";
export default {
  name: "VirHomePart1",
  data() {
    return {
      activeKey: "",
      VirHomePart1_Data,
    };
  },
  created() {
    this.activeKey = VirHomePart1_Data[0].name;
  },
  mounted() {
    new WOW.WOW().init();
    document
      .querySelectorAll(".VirHomePart1 .tabs .ant-tabs-nav>div div")
      .forEach((ele) => {
        ele.addEventListener("mouseenter", this.mouseEnterTabsEvent);
      });
  },
  beforeDestroy() {
    document
      .querySelectorAll(".VirHomePart1 .tabs .ant-tabs-nav>div div")
      .forEach((ele) => {
        ele.removeEventListener("mouseenter", this.mouseEnterTabsEvent);
      });
  },

  methods: {
    mouseEnterTabsEvent(e) {
      this.activeKey = e.target.innerText;
    },
  },
};
</script>

<style scoped lang="scss">
.VirHomePart1 {
  .brand_img {
    width: 62px;
    height: 62px;
  }
  .brand_title {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
    font-weight: 400;
    color: #666;
    margin-top: 6px;
  }
  .brand_box {
    text-align: center;
    background: #fff;
    width: 106px;
    height: 106px;
    box-shadow: 0 3px 18px 1px rgba(0, 0, 0, 0.05);
    border-radius: 10px 10px 10px 10px;
    padding: 10px 0;
    transition: all 0.3s;
    &:hover {
      transform: scale(1.2);
      box-shadow: 0 3px 20px 1px rgba(0, 0, 0, 0.16);
    }
  }
}
</style>

<style scoped lang="scss">
.VirHomePart1 {
  padding-top: 100px;
  position: relative;

  background: #f6f8fa;
}
.tab-pane {
  // border-top: 1px solid #18497a;
  padding-top: 25px;
}
</style>
