/**
 * @author  XuHongli
 * @date  2022/12/26 15:20
 * @version 1.0
 * @description
 */

const VirHomePart5_Data = [
  {
    name: '银行',
    icon: '/mp/ying.png',
    selectIcon: '/mp/ying1.png',
    imgUrl: '/mp/yihang.png',
    text: '通过搭建积分商城搭建，提供创新营销玩法、专业技术开发，通过各种营销模式拉近银行和用户的关系。'
  },
  {
    name: '保险',
    icon: '/mp/product_gn_bx.png',
    selectIcon: '/mp/product_gn_bx1.png',
    imgUrl: '/mp/sycj_bj_0002_5.png',
    text: '1000+权益产品，匹配相应消费场景，无需物流、无需包装、活动奖品即中即得，企业省心省力。'
  },
  {
    name: '互联网',
    icon: '/mp/product_gn_hlw.png',
    selectIcon: '/mp/product_gn_hlw1.png',
    imgUrl: '/mp/sycj_bj_0003_4.png',
    text: '高性价比商品、规范采购流程、简单接入方式，保障服务品质满足个性化需求。'
  },
  {
    name: '通讯',
    icon: '/mp/product_gn_tx.png',
    selectIcon: '/mp/tongxun.png',
    imgUrl: '/mp/sycj_bj_0004_3.png',
    text: '定制化营销系统、全品类商品供应、多种结算模式，根据每个企业的用户特点搭配方案，节省成本，极大调动用户参与积极性。'
  },
  {
    name: '新零售',
    icon: '/mp/product_gn_xls.png',
    selectIcon: '/mp/product_gn_xls1.png',
    imgUrl: '/mp/sycj_bj_0005_4.png',
    text: '提供会员积分商城、自有商品配置、多场景发放。'
  },
  {
    name: '生活服务',
    icon: '/mp/product_gn_shfw.png',
    selectIcon: '/mp/product_gn_shfw1.png',
    imgUrl: '/mp/sycj_bj_0006_3.png',
    text: '根据企业需求提供多种互动权益解决方案，官方正品保障、贴合用户需求、低成本零风险。'
  }
]

export default VirHomePart5_Data
