/**
 * @author  XuHongli
 * @date  2022/12/23 17:34
 * @version 1.0
 * @description
 */

const VirHomePart1_Data = [
  {
    name: '出行车服',
    children: [
      {
        name: '哈啰出行',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/cxcf_img_001.png'
      },
      {
        name: '哈啰打车',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/cxcf_img_002.png'
      },
      {
        name: '滴滴出行',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/cxcf_img_003.png'
      },
      {
        name: '枫车养车',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/cxcf_img_004.png'
      },
      {
        name: 'e代驾',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/cxcf_img_005.png'
      },
      {
        name: '同城旅行',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/cxcf_img_006.png'
      },
      {
        name: '拼客顺风车',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/cxcf_img_007.png'
      },
      {
        name: '同程打车',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/cxcf_img_008.png'
      },
      {
        name: '高德出行',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/cxcf_img_009.png'
      },
      {
        name: '青桔单车',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/cxcf_img_010.png'
      },
      {
        name: '凹凸打车',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/cxcf_img_011.png'
      },
      {
        name: 'T3出行',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/cxcf_img_012.png'
      },
      {
        name: '滴答出行',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/cxcf_img_013.png'
      },
      {
        name: '花小猪',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/cxcf_img_014.png'
      }
    ]
  },
  {
    name: '知识阅读',
    children: [
      {
        name: '掌阅',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/zsyd_img_001.png'
      },
      {
        name: '得到',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/zsyd_img_002.png'
      },
      {
        name: '快看',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/zsyd_img_003.png'
      },
      {
        name: 'QQ阅读',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/zsyd_img_004.png'
      },
      {
        name: '樊登读书',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/zsyd_img_005.png'
      },
      {
        name: '作业帮',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/zsyd_img_006.png'
      },
      {
        name: '知乎',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/zsyd_img_007.png'
      },
      {
        name: 'islide',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/zsyd_img_008.png'
      },
      {
        name: '有书',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/zsyd_img_009.png'
      },
      {
        name: '常青藤爸爸',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/zsyd_img_010.png'
      },
      {
        name: '洪恩识字',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/zsyd_img_011.png'
      },
      {
        name: '十点读书',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/zsyd_img_012.png'
      },
      {
        name: 'KaDa故事',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/zsyd_img_013.png'
      },
      {
        name: '凯叔讲故事',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/zsyd_img_014.png'
      },
      {
        name: '一起学',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/zsyd_img_015.png'
      }
    ]
  },
  {
    name: '生活工具',
    children: [
      {
        name: '时光相册',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/shgj_img_001.png'
      },
      {
        name: '印鸽',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/shgj_img_002.png'
      },
      {
        name: '天眼查',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/shgj_img_003.png'
      },
      {
        name: 'VUE vlog',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/shgj_img_004.png'
      },
      {
        name: '印象笔记',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/shgj_img_005.png'
      },
      {
        name: '小影',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/shgj_img_006.png'
      },
      {
        name: '脉脉',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/shgj_img_007.png'
      },
      {
        name: 'WPS会员',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/shgj_img_008.png'
      },
      {
        name: '小电充电',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/shgj_img_009.png'
      },
      {
        name: '爱企查',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/shgj_img_010.png'
      },
      {
        name: '和讯',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/shgj_img_011.png'
      },
      {
        name: '花加',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/shgj_img_012.png'
      },
      {
        name: '虎嗅',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/shgj_img_013.png'
      },
      {
        name: '海看爱宠',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/shgj_img_014.png'
      }
    ]
  },
  {
    name: '咖啡饮品',
    children: [
      {
        name: '奈雪的茶',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/glyp_img_001.png'
      },
      {
        name: '太平洋咖啡',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/glyp_img_002.png'
      },
      {
        name: '瑞幸咖啡',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/glyp_img_003.png'
      },
      {
        name: '喜茶',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/glyp_img_004.png'
      },
      {
        name: '七分甜',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/glyp_img_005.png'
      },
      {
        name: 'coco',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/glyp_img_006.png'
      },
      {
        name: '书亦烧仙草',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/glyp_img_007.png'
      },
      {
        name: '乐乐茶',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/glyp_img_008.png'
      },
      {
        name: '茶百道',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/glyp_img_009.png'
      },
      {
        name: 'TIMS咖啡',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/glyp_img_010.png'
      },
      {
        name: '一鸣真鲜奶吧',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/glyp_img_011.png'
      },
      {
        name: '蜜雪冰城',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/glyp_img_012.png'
      },
      {
        name: '古茗',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/glyp_img_013.png'
      },
      {
        name: '和气桃桃',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/glyp_img_014.png'
      }
    ]
  },
  {
    name: '影音娱乐',
    children: [
      {
        name: 'QQ音乐',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yyyl_img_001.png'
      },
      {
        name: '喜马拉雅',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yyyl_img_002.png'
      },
      {
        name: '蜻蜓FM',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yyyl_img_003.png'
      },
      {
        name: '酷狗音乐',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yyyl_img_004.png'
      },
      {
        name: 'COCO',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yyyl_img_006.png'
      },
      {
        name: '酷我音乐',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yyyl_img_007.png'
      },
      {
        name: '爱奇艺',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yyyl_img_008.png'
      },
      {
        name: '优酷音乐',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yyyl_img_009.png'
      },
      {
        name: '百度网盘',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yyyl_img_010.png'
      },
      {
        name: '芒果TV',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yyyl_img_011.png'
      },
      {
        name: '南瓜电影',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yyyl_img_012.png'
      },
      {
        name: '懒人听书',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yyyl_img_013.png'
      },
      {
        name: '哔哩哔哩',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yyyl_img_014.png'
      }
    ]
  },
  {
    name: '医护健康',
    children: [
      {
        name: 'Keep',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yhjk_img_001.png'
      },
      {
        name: 'fit健康',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yhjk_img_002.png'
      },
      {
        name: '贝色口腔',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yhjk_img_003.png'
      },
      {
        name: '畅呼医疗',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yhjk_img_004.png'
      },
      {
        name: '春雨医生',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yhjk_img_005.png'
      },
      {
        name: '即刻运动',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yhjk_img_006.png'
      },
      {
        name: '善诊',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yhjk_img_007.png'
      },
      {
        name: '微医',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yhjk_img_008.png'
      },
      {
        name: '腾讯微保',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yhjk_img_009.png'
      },
      {
        name: '医联',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yhjk_img_010.png'
      },
      {
        name: '乐动力',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yhjk_img_011.png'
      },
      {
        name: '每日瑜伽',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yhjk_img_012.png'
      },
      {
        name: '名医看牙',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/yhjk_img_013.png'
      }
    ]
  },
  {
    name: '聚餐美食',
    children: [
      {
        name: '小杨生煎',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/jcms_img_001.png'
      },
      {
        name: '满记甜点',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/jcms_img_002.png'
      },
      {
        name: '茶颜悦色',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/jcms_img_003.png'
      },
      {
        name: '来伊份',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/jcms_img_004.png'
      },
      {
        name: '肯德基',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/jcms_img_005.png'
      },
      {
        name: '华莱士',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/jcms_img_006.png'
      },
      {
        name: '汉堡王',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/jcms_img_007.png'
      },
      {
        name: '必胜客',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/jcms_img_008.png'
      },
      {
        name: '哈根达斯',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/jcms_img_009.png'
      },
      {
        name: '21cake蛋糕',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/jcms_img_010.png'
      },
      {
        name: '幸福西饼',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/jcms_img_011.png'
      },
      {
        name: '巴黎贝甜',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/jcms_img_012.png'
      },
      {
        name: '歌帝梵',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/jcms_img_013.png'
      },
      {
        name: '德克士',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/jcms_img_014.png'
      },


    ]
  },
  {
    name: '商超购物',
    children: [
      {
        name: '百果园',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/scgw_img_001.png'
      },
      {
        name: '本来生活',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/scgw_img_002.png'
      },
      {
        name: '盒马鲜生',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/scgw_img_003.png'
      },
      {
        name: '华润万家',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/scgw_img_004.png'
      },
      {
        name: '来伊份',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/scgw_img_006.png'
      },
      {
        name: '良品铺子',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/scgw_img_007.png'
      },
      {
        name: '屈臣氏',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/scgw_img_008.png'
      },
      {
        name: '三只松鼠',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/scgw_img_009.png'
      },
      {
        name: '天猫超市',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/scgw_img_010.png'
      },
      {
        name: '沃尔玛',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/scgw_img_011.png'
      },
      {
        name: '永辉超市',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/scgw_img_012.png'
      },
      {
        name: '大润发',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/scgw_img_013.png'
      },
      {
        name: '多点',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/icon/scgw_img_014.png'
      }
    ]
  }
]

export default VirHomePart1_Data
