/**
 * @author  XuHongli
 * @date  2022/12/26 12:02
 * @version 1.0
 * @description
 */
const VirHomePart6_Data = [
  {
    title: '0成本开发',
    text: 'H5嵌入无需开发轻松对接，紧跟市场变化，模式功能迅速迭代',
    imgUrl: '/mp/qyfw_img_001.png'
  },
  {
    title: '灵活定制服务',
    text: '从产品配置到技术研发，量身定制多维度解决方案',
    imgUrl: '/mp/qyfw_img_002.png'
  },
  {
    title: '优质权益资源',
    text: '全网优质权益商品资源，覆盖吃喝玩乐生活场景，提高会员粘性',
    imgUrl: '/mp/qyfw_img_003.png'
  },
  {
    title: '7X24小时服务',
    text: '拥有完善的客户服务系统，及时响应客户问题',
    imgUrl: '/mp/qyfw_img_004.png'
  }
]


export default VirHomePart6_Data
