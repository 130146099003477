<template>
  <div class="VirHomePart6">
    <div class="brand_block wow animate__zoomIn" data-wow-duration="1s">
      <div class="block_maintitle">企业级服务</div>
      <div class="block_subtitle">快速触达精准用户，升级用户忠诚度</div>
    </div>
    <div class="product_content">
      <div
        class="product_li wow animate__zoomIn"
        data-wow-duration="1s"
        v-for="(item, index) in VirHomePart6_Data"
        :key="index"
      >
        <div class="product_cont_nav">
          <div class="product_cont">
            <div class="product_headline">
              <img :src="item.imgUrl" alt="" />
            </div>
            <div class="product_right">
              <div class="product_effect">
                {{ item.title }}
              </div>
              <div class="product_subtitle">
                {{ item.text }}
              </div>
              <div class="product_join">
                <a href="javascript:void(0)" target="_blank"> 立即体验 > </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VirHomePart6_Data from "@/views/ProductServer/VirHome/Data/VirHomePart6_Data";

/**
 * @author  XuHongli
 * @date  2022/12/26 11:59
 * @version 1.0
 * @description
 */
import WOW from "wowjs";
export default {
  name: "VirHomePart6",
  data() {
    return {
      VirHomePart6_Data,
    };
  },
  mounted() {
    new WOW.WOW().init();
  },
};
</script>

<style scoped lang="scss">
.VirHomePart6 {
  width: 100%;
  position: relative;
  padding: 120px 0;
}
.product_content {
  width: 1280px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 60px auto auto;
  .product_li {
    width: 624px;
    border-radius: 10px;
    box-shadow: 0 3px 29px 1px rgba(73, 75, 78, 0.16);
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }
}
.product_cont_nav {
  transition: all 0.8s;
  width: 100%;
  .product_cont {
    transition: all 0.8s;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    .product_headline {
      overflow: hidden;
      transition: all 0.8s;
      img {
        transition: all 0.8s;
        width: 330px;
        height: 100%;
      }
    }
    .product_right {
      padding-left: 32px;
      flex: 1;
    }
    .product_effect {
      font-size: 20px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
      font-weight: 600;
      color: #000;
      line-height: 26px;
      margin: 42px 0 32px;
      position: relative;
      &::after {
        content: "";
        width: 33px;
        height: 4px;
        background: #ff6a00;
        left: 0;
        bottom: -18px;
        position: absolute;
      }
    }
    .product_subtitle {
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
      font-weight: 400;
      color: #999;
      line-height: 23px;
      width: 252px;
    }
    .product_join {
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
      font-weight: 400;
      color: #ff6a00;
      margin-top: 40px;
      a {
        color: #ff6a00;
      }
    }
  }
}
.product_cont:hover .product_headline img {
  transform: scale(1.2);
  transition: all 0.8s;
}
</style>
